.pageheader-container {
  display: flex;
  width: 100%;
  margin-top: 14px;
}

.pageheader-title {
  flex: 1;
}

@media screen and (max-width: 1140px) {
  .pageheader-container {
    margin-top: 0px;
  }
  .pageheader-title {
    margin-top: 16px;
    margin-bottom: 0px;
  }
}