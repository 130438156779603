/* Futura medium */
@font-face {
  font-family: futuraBold;
  src: url(../fonts/Futura-Bold.woff);
}

@font-face {
  font-family: futuraMedium;
  src: url(../fonts/Futura-Medium.woff);
}

@font-face {
  font-family: roboto;
  src: url(../fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: Inter;
  src: url(../fonts/Inter-Regular.ttf);
}