.input-checkbox-label {
  text-align: center;
  display: flex;
  margin: 0px 0px 14px;
  cursor: pointer;
  font-size: 1em;
  align-items: center;
  color: black;
}

#input-checkbox{
  margin: 0 20px 0 0;
}