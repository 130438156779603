.secondary-navbar {
    width: 100vw;
    height: 30px;
    padding: 5px 0;
    background-color: white;
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12), 0px 1px 2px 0px rgba(0,0,0,0.24);
    position: fixed;
    top: 105.81px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondary-navbar-items {
    list-style-type: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin: 0;
    padding: 0;
}

.secondary-navbar-items-span {
    font-size: 15px;
    color: #1a1a1a;
    font-weight: 200;
}

.secondary-navbar-items-span:hover {
    font-weight: 600;
}