.date-picker {
  display: flex;
  width: 100%;

  font-family: futuraMedium;
  font-weight: 100;
  font-size: 14px;
  color: #b7b7b7;

  min-height: 40px;

  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.date-picker input {
  margin-bottom: 0px;
}

@media screen and (max-width: 760px) {
  .ant-picker-panels{
    flex-direction: column;
  }
}

@media screen and (max-width: 760px){
  .ant-picker-panels .ant-picker-body{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .ant-picker-panels .ant-picker-cell{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}