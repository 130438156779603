/* Override some antd styling */
.ant-timeline-item {
  padding-bottom: 30px !important;
}
/* Override some antd styling */

.timeline-date,
.timeline-details {
  font-family: futuraMedium;
}

.timeline-date {
  font-size: 14px;
  font-weight: 100;
  margin-right: 10px;
}

.timeline-details {
  font-size: 15px;
  margin: 0 10px;
}

.timeline-status {
  font-weight: 900;
}
