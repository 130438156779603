.searchbar {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 12px;
}

.searchbar-input {
  flex: 1;
  height: 40px;
  padding-left: 32px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.searchbar-input::placeholder {
  font-family: futuraMedium;
  font-weight: 100;
  font-size: 14px;
  color: #b7b7b7;
}

.searchbar-icon {
  position: absolute;
  left: 10px;
  z-index: 2;
  color: #b7b7b7;
  font-size: 16px;
}

@media screen and (max-width: 760px) {
  .searchbar-input {
    height: 56px;
    margin-bottom: 0px;
  }
  .searchbar-icon {
    margin-bottom: 0px;
  }
}