.custom-dropdown-container {
  position: relative;
  display: flex;
  width: 100%;
}

.custom-dropdown,
.custom-dropdown-disabled {
  flex: 1;
  cursor: pointer;

  display: flex;
  align-items: center;
  max-height: 40px;

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.custom-dropdown-disabled {
  cursor: not-allowed;
  background-color: #dddddd;
}

.custom-dropdown-placeholder {
  font-family: futuraMedium;
  font-weight: 100;
  font-size: 14px;
  color: #b7b7b7;

  margin-left: 12px;
  margin-bottom: 0;
}

.custom-dropdown-value {
  font-family: futuraMedium;
  font-weight: 100;
  font-size: 14px;
  color: #000;

  margin-left: 12px;
  margin-bottom: 0;
}

.custom-dropdown-icon {
  color: #b7b7b7;
  font-size: 20px;
  font-weight: 100;

  margin-left: auto;
  margin-right: 12px;
}

.custom-dropdown-options {
  position: absolute;
  z-index: 10;

  width: 100%;
  top: 50px;
  background-color: #2f2f2f;

  padding: 4px;
  border-radius: 10px;
}

.custom-dropdown-option {
  font-family: futuraMedium;
  font-weight: 100;
  font-size: 12px;
  color: #fff;

  padding: 2px 4px;
  margin: 4px 2px;
}

.custom-dropdown-option:hover {
  cursor: pointer;
  background-color: #f5dd4be5;
  border-radius: 10px;
  color: #000;
}
