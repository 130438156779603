.shipment-container {
  text-align: left;
}
.align-center {
  text-align: center;
}
.pl-tbody .subscriber-row {
  width: 100% !important;
}
.pl-tbody .row .scroll {
  max-height: 100px;
  overflow-x: scroll;
}
.shipment-container .btn-add-user {
  width: auto;
}
.shipment-container .btn-load-more {
  background: #000;
  padding: 10px;
  margin-top: 15px;
  margin: 15px 43%;
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.shipments {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 42px;
}
.print-waybill-btn {
  font-family: futuraMedium;
  font-size: 16px;
  text-align: center;

  color: #f7d700;
  background-color: #000;

  width: 120px;
  height: 45px;

  padding: 12px;

  border-radius: 10px;
  box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.25);
}
.print-waybill-btn:disabled {
  color: #979797;
  background-color: #DFDFDF;
  box-shadow: none;
}
.shipments-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 12px;
}
.shipment-table {
  display: grid;
  grid-template-columns: 60% 39%;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  max-height: 600px;
}

.shipment-info {
  padding: 4px 24px;
  overflow-y: auto;
  max-height: 500px;
}

.shipment-info-label,
.shipment-info-value {
  font-family: futuraMedium;
  font-size: 14px;
  width: 100%;

  padding: 12px 12px;
  margin: 0;
}
.shipment-info-label {
  font-weight: 900;
}
.shipment-info-value {
  font-weight: 100;
  border-radius: 5px;
  background-color: #eeeeee;

  min-height: 40px;
  margin-bottom: 10px;
}

.shipment-no-info,
.shipment-no-history {
  font-family: futuraMedium;
  font-size: 16px;
  display: grid;
  place-items: center;
  color: #d2d2d2;
  margin-top: 50px;
  margin-bottom: 50px;
}

#shipment-filter {
  float: none;
  width: 200px;
  font-size: 12px;
}

.shipment-filter-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 24px;
}
.shipment-filter-search {
  width: 50%;
}
.shipment-filter-dropdowns {
  width: 25%;
}

.shipment-history {
  overflow: hidden;
  margin-top: 16px;
  margin-left: -15vw;
  padding: 10px;
}

@media screen and (max-width: 1140px) {
  .shipment-table {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 18px;
    margin-top: 10px;
  }

  .shipments-pagination {
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 760px) {
  .shipment-filter-search {
    flex-grow: 1;
  }

  .shipment-filter-dropdowns {
    display: none;
    width: 0;
  }

  .shipment-filter-chip {
    margin: 0;
    margin-right: 0.5rem
  }

  .shipment-filter-button {
    display: block;
    background-color: rgba(245,221,75,255);
    height: 56px;
    width: 56px;
    border-radius: 10px;
    border-width: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .shipment-filter-container {
    gap: 0px;
    align-items: end;
    margin-bottom: 10px;
  }
  
  .shipment-filter-button-container{
    height: 56px;
    width: 56px;
    margin-left: 16px;
    display: block;
  }
}

@media screen and (min-width: 761px) {
  .shipment-filter-button {
    display: none;
  }

  .shipment-filter-button-container{
    display: none;
  }
}

/* Only used on agents side of shipment table */
.shipment-filter-label {
  font-size: 14px;
  margin-right: 1em;
}

.shipment-filter-chip {
  margin-right: 0.5rem;
}

.shipment-status-text {
  font-family: futuraMedium;
  font-size: 12px;
}

.shipment-action-text {
  font-family: futuraMedium;
  font-size: 12px;
  color: #38b0d4;

  transition: opacity 0.3s;
}
.shipment-action-text:hover {
  cursor: pointer;
  opacity: 0.5;
}
.easy-edit-inline-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.easy-edit-component-wrapper input {
  margin-bottom: 0;
}
