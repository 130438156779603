/* REALLY NEED TO TAKE A LOOK INTO REMOVING / REFACTORING THESE STYLING AS IT MAKES IT HARD TO STYLE COMPONENTS INDIVIDUALLY */

body {
  font-family: futuraBold;
  color: black;
  background-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 16px !important;
}

h1 {
  font-size: 55px;
  margin-top: 2em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 45px;
  margin: 0.75em;
  margin-bottom: 0.2em;
}

h1,
h2 {
  font-weight: 600;
}

h4 {
  font-size: 20px;
  font-weight: 600;
}

h4,
h5,
p,
a,
label,
input,
textarea,
.btn,
.caption,
.detail,
.detail-two {
  font-family: futuraMedium;
  letter-spacing: -0.2px;
}

h6 {
  font-size: 10px;
  font-weight: lighter;
}

p,
label {
  letter-spacing: -0.5px;
  font-size: 27px;
  font-weight: 600;
}

a {
  letter-spacing: -0.5px;
}

a:hover,
a:visited,
a:active,
a:focus {
  text-decoration: none;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.container-wide {
  width: 100%;
}

.caption {
  font-size: 1.7em;
  letter-spacing: -0.5px;
  margin-top: 1em;
}

.sub-caption,
.form-label {
  font-size: 1.3em;
  letter-spacing: -0.5px;
}

.detail {
  text-align: left;
  margin-left: 1em;
  font-weight: 600;
  margin-bottom: 0.8em;
  font-size: 16px;
}

.detail-two {
  text-align: center;
  font-weight: 600;
  margin-bottom: 0.8em;
  font-size: 16px;
}

.detail-three {
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 16px;
}

.forgot-password {
  width: 100%;
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.forgot-password:focus {
  outline: none;
}

.forgot-password:hover {
  color: #38b0d4;
}

.form-label {
  margin-left: 2em;
}

.parallax {
  background-image: url('../images/flying-tigers-express-white-tiger-stripes-pattern.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 980px !important;
}

.parallax-prohibited-items {
  background-image: url('../images/flying-tigers-express-white-tiger-stripes-pattern.png');
  background-position: center;
  position: absolute;
  height: auto;
  margin-top: 7em !important;
}

#parallax-about-us {
  margin-top: 3em !important;
}

.parallax-home {
  background-image: url('../images/Tiger_Print_BG.png');
}

.parallax-2 {
  background: #f2f2f2;
}

.parallax,
.parallax-2 {
  position: absolute;
  height: auto;
  padding-bottom: 10vh;
  margin-top: 7em !important;
}

.container-has-secondary-navbar {
  margin-top: 135.81px !important;
}

.parallax-text {
  margin-top: 4em;
}

.contact-us p {
  margin: auto;
  font-size: 2.5em;
}

.air-waybill {
  background: #fff;
  padding: 2em;
  text-align: left;
  min-width: 767px;
}

.log-out {
  color: red;
}

.scroll-mobile {
  overflow-x: auto;
}

/* Login form */

#loginPanel,
#userMenu {
  font-family: futuraMedium;
  text-align: left;
  color: black;
  padding: 0 18px;
  overflow: hidden;
  width: 250px;
  left: 80%;
  top: 12%;
  height: auto;
  position: fixed;
  background-color: #fff;
  margin-top: auto;
}

#userMenu {
  width: 150px;
  left: 86%;
  text-align: center;
  border: none;
  background-color: #fadc06;
}

#userMenu a {
  color: black;
}

#userMenu a:hover,
.log-out:hover {
  color: #fff;
}

/* Style the tab */

.tab {
  overflow: hidden;
  background-color: transparent;
  margin: auto;
  margin-top: 4em;
}

/* Style the buttons that are used to open the tab content */

.tablink {
  font-family: futuraBold;
  background-color: transparent;
  color: black;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  padding: 5px 16px;
  margin-left: 10px;
  margin-right: 10px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* Change background color of buttons on hover */

.tablink:hover {
  background-color: black;
  color: #f7d700;
}

.tab .active-tab,
.btn {
  background-color: black;
  color: #f7d700;
}

.tracking-number {
  width: -webkit-fill-available;
  height: auto;
  padding: 10px;
  font-size: x-large;
  font-weight: 600;
  border: 7px solid black;
}

.tracking-number[type='number']::-webkit-inner-spin-button,
.tracking-number[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tracking-number[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.added_field {
  margin-bottom: -25px;
}

/* Button styles */

.btn {
  font-size: 1.2em;
  letter-spacing: -0.5px;
  margin: 1em;
  width: 15em;
  padding: 0.3em;
  border: black;
  border-radius: 0;
}

.btn:hover {
  color: #fff;
}

.btn-nav-login {
  padding: 0px;
  margin-bottom: 3px !important;
}

.btn-nav-login,
.btn-nav-user {
  margin: auto;
  width: auto;
  background: transparent;
  font-size: inherit;
}

.btn-nav-login:hover,
.btn-nav-user:hover {
  color: #fff;
}

.btn-nav-login:active,
.btn-nav-login:focus,
.btn-nav-login:visited,
.btn-nav-user:active,
.btn-nav-user:focus,
.btn-nav-user:visited {
  color: #e6c702;
  outline: none;
}

.btn-upload {
  font-size: 12px;
  letter-spacing: 0;
  width: 10em;
  margin-top: auto;
  margin: auto;
}

.btn-waybill {
  width: 40%;
  margin-top: 1.5em;
  margin-bottom: 5px;
  min-width: 200px;
}

.link-homepage {
  margin: 3em;
  font-size: 1.4em;
  outline-width: 20px;
}

.detail-label {
  text-align: left;
  font-family: futuraBold;
  margin-left: 1em;
}

.detail-label-two {
  text-align: center;
  font-family: futuraBold;
}

/* Table */

table thead th,
table tbody td {
  text-align: center;
  padding-left: 1px;
  padding-right: 1px;
}

.table {
  margin-top: 2em;
  margin-bottom: 0;
  max-width: 100%;
}

.table thead tr th {
  text-align: center;
  font-size: 25px;
  min-width: 9em;
  border-bottom: none;
}

.table tbody tr td {
  border: none;
  padding: 6px;
}

.delivered {
  color: #3cb64d;
}

.canceled {
  color: rgba(241,94,94,255)
}

.ongoing {
  color: #3367D6
}

.waybills-table {
  background-color: #ffffff73;
}

.waybills-table tbody tr td a {
  font-size: 18px;
  color: black;
}

.waybills-table tbody tr td a:hover {
  color: grey;
}

/* For Sign Up Form */

.create-account {
  background: rgba(0, 0, 0, 0);
  max-width: 400px;
  margin: auto;
  padding: 2px;
  margin-top: 4em;
}

.create-account form {
  text-align: left;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0px;
}

.create-account h4 {
  margin: 0;
  font-size: 24px;
}

input,
textarea,
select {
  height: 2.5em;
  margin-bottom: 1em;
  padding: 5px;
  border: none;
  font-weight: 600;
}

input:focus,
textarea:focus {
  outline: none;
}

.login-panel input {
  font-weight: 100;
  background: #f2f2f2;
  width: -webkit-fill-available;
  font-size: 15px;
}

textarea {
  height: 8em;
  width: -webkit-fill-available;
}

select {
  float: left;
  width: -webkit-fill-available;
  font-size: 1em;
  border: none;
  margin-bottom: 1em;
  height: 2.5em;
}

.btn-add,
.btn-remove {
  color: black;
  font-size: 2.5em;
  padding-top: 0.5em;
  padding-bottom: 1.2em;
  margin-left: -50px;
}

.button_remove {
  margin-top: 22px;
  /*padding-top: 2em;*/
}

.glyphicon-menu-down {
  font-size: 12px;
}

.glyphicon-search {
  margin-right: 25px;
}

.input-dimension {
  float: left;
  margin-right: 2em;
  width: 80%;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

input[type='radio'] {
  margin: 4px 0 0;
  line-height: normal;
  margin-left: 1em;
  margin-right: 1em;
  vertical-align: bottom;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.create-account .btn {
  width: 90%;
  margin-top: 2em;
}

.form-control {
  border: 1px solid #fff;
  border-radius: 0;
}

.form-header {
  font-family: futuraBold;
  text-align: left;
  font-size: large;
}

.form-label {
  text-align: left;
  margin-right: 1em;
}

tr h4 {
  background: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: auto;
  min-width: 10em;
  letter-spacing: -1px;
  font-size: 2em;
}

tr a {
  min-width: 14em;
  color: #38b0d4;
  font-family: futuraMedium;
  font-size: 18px;
  letter-spacing: -0.4px;
  padding-top: 10px;
}

/* NAVIGATION */

#nav li {
  display: inline-block;
  padding: 1em;
}

#rightNavbar,
#rightNavbarMobile,
#nav a,
.nav-right-item a {
  color: #e6c702;
  background-color: transparent;
  font-size: 18px;
}

#nav a {
  padding: 10px 0 !important;
}

#mainNavbar {
  text-align: center;
  margin-left: 10%;
}

#rightNavbar, #rightNavbarMobile {
  float: right;
  margin: 16px;
  margin-top: 33px;
}

.navbar {
  background-color: #1a1a1a;
  min-height: 100px;
  font-family: futuraMedium;
  z-index: 4 !important;
}

.nav {
  margin: 1em;
}

.nav-right-item,
#rightNavbar a:visited,
#rightNavbar a:active,
#rightNavbarMobile a:visited,
#rightNavbarMobile a:active {
  color: #e6c702;
  text-decoration: none;
}

#nav a:hover,
#rightNavbar a:hover,
#rightNavbarMobile a:hover {
  color: white;
  background-color: transparent;
}

.navbar-center {
  margin: auto;
  text-align: center;
}

.navbar-inverse .navbar-toggle {
  border-color: #3330;
}

.navbar-toggle {
  float: left;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #e6c702;
  height: 4px;
  width: 26px;
}

/* FOOTER */

footer {
  height: 350px;
  background-color: #1a1a1a;
}

.row-footer,
.row-header {
  margin: auto;
  width: 15%;
  padding: 15px;
  margin-top: 2em;
}

.row-header {
  width: 45%;
}

.footer-link {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.footer-link li {
  display: inline;
}

.footer-link a {
  display: inline-block;
  padding: 8px;
}

.footer-link a,
.footer-social-media,
.copyright {
  color: black;
  font-weight: normal;
}

.footer-social-media {
  margin: auto;
  text-align: center;
}

.fa-stack a {
  color: black;
  font-size: 20px;
}

#header-logo {
  margin: 0.28em;
  margin-left: 1em;
  height: 7em;
}

#about-us-logo {
  height: 450px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fadc06;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

/* Text Color Helpers */

.black-text {
  color: #000000;
}

.mt-5r {
  margin-top: 5rem !important;
}

#sub-menu {
  margin: 0;
  padding: 0;
  margin-top: 35px;
}

#sub-menu li {
  list-style: none;
  display: inline-block;
  padding-bottom: 20px;
}

#sub-menu li a {
  text-decoration: none;
  position: relative;
  color: rgba(143, 143, 143, 0.59);
  font-size: 20px;
  font-weight: 500;
  font-family: futuraBold;
  font-size: 20px;
  font-style: normal;
  line-height: 38px;
  letter-spacing: 0.5px;
  display: block;
  overflow: hidden;
  transition: all 0.5s ease-in;
  padding: 5px 8px;
  margin: 0 20px;
  text-transform: inherit;
  /* identical to box height, or 190% */
  letter-spacing: 0.5px;
  color: rgba(143, 143, 143, 0.59);
}

#sub-menu li a:before {
  content: '';
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  margin: auto;
  right: 350px;
}

#sub-menu li a:hover:before {
  right: 0;
}

#sub-menu li a.active {
  font-family: futuraBold;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: #000000;
  padding-bottom: 15px;
  border-bottom: 6px solid #fadc06;
  padding-left: 0;
  padding-right: 0;
  top: 15px;
}

#sub-menu ul {
  padding-inline-start: 8px;
}

.progress-checklist-container {
  text-align: left;
  padding-left: 60px;
  padding-top: 70px;
  padding-bottom: 200px;
}

.checklist-check {
  margin-right: 18px;
  padding: 9px 7px;
  background: #ffffff;
  border: 1.81882px solid #f7d700;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
}

.checklist-outer-circle {
  margin-right: 18px;
  margin-bottom: -7px;
  display: inline-block;
  background: #fff;
  border-radius: 16px;
  border: 1.81882px solid #fff3a1;
  height: 32px;
  width: 32px;
  position: relative;
}

.checklist-inner-circle {
  position: absolute;
  background: #fff3a1;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  top: 7px;
  left: 7px;
}

.progress-checklist-active {
  font-family: futuraMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 13.6411px;
  line-height: 23px;
  letter-spacing: 0.454704px;
  color: #333333;
  height: 32px;
}

.progress-checklist-active > .checklist-text {
  font-family: futuraMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 13.6411px;
  line-height: 23px;
  letter-spacing: 0.454704px;
  color: #333333;
  margin-top: 2px;
  margin-bottom: 5px;
  margin-left: 0.03em;
}

.progress-checklist {
  font-family: futuraMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 13.6411px;
  line-height: 23px;
  letter-spacing: 0.454704px;
  color: #828282;
  height: 32px;
}

.progress-checklist > .checklist-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13.6411px;
  line-height: 23px;
  color: #828282;
  font-family: futuraMedium;
  letter-spacing: 0.454704px;
  display: inline-block;
  position: relative;
  top: -6px;
}

.checklist-vert-bar {
  border-left: solid 1px #c4c4c4;
  height: 40px;
  margin: 4px 0px 4px 16px;
}

.progress-checklist-container {
  text-align: left;
  padding-left: 60px;
  padding-top: 70px;
  padding-bottom: 200px;
}

.ship-step-row-container {
  max-width: 600px;
}

.ship-step-container {
  text-align: left;
  padding-top: 40px;
}

.ship-step-numbering {
  letter-spacing: -4px;
  font-family: futuraMedium;
  font-size: 20px;
  line-height: 36px;
  color: #333333;
  border: 2.6px solid #bdbdbd;
  border-radius: 20px;
  padding: 4px 12px 4px 8px;
  margin-right: 30px;
}

.ship-step-container-responsive {
  display: none;
}

.ship-step-content-container {
  margin-top: 0;
  max-width: 402px;
}

.ship-step-content {
  padding-left: 40px;
}

.ship-step-title {
  font-family: futuraMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: 0.2px;
  color: #000000;
  display: block;
}

.ship-step-title-cost {
  margin-top: 40px;
  padding-bottom: 26px;
  max-width: 582px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  border-bottom: 1px dashed #cecece;
}

.select-primary:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(../images/dropdown_icon.png);
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=); */
  padding-bottom: 6px;
  padding-left: 0;
  outline: none;
}

.select-primary {
  font-family: roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #828282;
  border: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.42);
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 0px;
  outline: none;
  height: 40px;
  margin-bottom: 0;
  margin-top: -3px;
}

.select-primary:disabled {
  font-family: roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #c4c4c4;
  border: none;
  border-bottom: solid 1px #e4e4e4;
}

.select-primary:not([multiple]):disabled {
  background-image: none;
}

.label-select-primary {
  margin-top: 1.32em;
  font-family: Roboto;
  margin-bottom: 0;
  font-size: 0.858em;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.45);
}

.button-primary {
  float: right;
  font-family: futuraMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 20px 50px;
  border: none;
  outline: none;
  /* identical to box height, or 100% */
  color: #000000;
  background: #fadc06;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.1);
}

.button-primary:disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.8;
  background: #e9e9e9;
}

.button-secondary {
  font-family: futuraMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 18px 48px;
  border: 2px solid #fadc06;
  background: #fff;
  outline: none;
  color: #000000;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.1);
}

.button-air-waybill {
  text-align: center;
  padding: 20px 0;
  width: 272px;
}

.button-air-waybill:disabled {
  background: #fff4a2;
  color: #9c9965;
}

.previous-next-button-group {
  max-width: 402px;
  margin-top: 26px;
  margin-bottom: 26px;
}

.input-primary {
  margin-top: 16px !important;
  background-color: rgba(0, 0, 0, 0);
}

.input-primary input {
  padding-bottom: 14px;
  color: rgba(0, 0, 0, 0.54);
}

.input-primary input:after {
  /* transition-property: border-bottom; */
  transition-duration: 0ms;
}

.input-primary label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.input-primary p {
  color: #ff1616 !important;
}

.checkbox-primary {
  display: inline-block;
  margin-left: -12px;
  margin-top: 8px;
}

.checkbox-primary-text {
  margin-left: 8px;
  display: inline-block;
  font-family: futuraMedium;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  top: 3px;
  position: relative;
}

.package-type-icon-container {
  margin-top: 50px !important;
}

.package-type-icon-container
  img[class*='package-type']:not(.package-type-arrow) {
  max-height: 70%;
  max-width: 90%;
}

.package-type-arrow {
  width: 23.08px;
  height: 37px;
}

.package-type-arrow:hover {
  width: 23.08px;
  height: 37px;
  cursor: pointer;
}

.package-type-label {
  font-family: futuraBold;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.5px;
  color: #000000;
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.package-description{
  width: 100%; 
  justify-content: center; 
  align-items: center;
  display: flex;
}

.package-type-vertical-center {
  height: 220px;
  line-height: 220px;
}

.shipment-cost-details-panel {
  max-width: 582px;
  background: #ffffff;
  margin: auto;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 28px;
  margin-bottom: 16px;
  font-family: futuraMedium;
  line-height: 23px;
  letter-spacing: 0.454704px;
  color: #333333;
}

.shipment-cost-details-panel-text-left {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
}

.shipment-cost-details-panel-text-right {
  font-size: 13.6411px;
  text-align: right;
}

.shipment-cost-details-button-container {
  width: 582px;
  margin: auto;
  text-align: right;
  margin-top: 32px;
}

.track-label {
  font-size: 25px !important;
  color: #000000;
}

.col-center {
  padding-left: 15%;
}

.inquiry-header {
  font-size: 2rem;
  font-family: 'futuraMedium';
  margin: 4rem 0px;
}

.inquiry-label {
  font-size: 1.75rem;
  font-family: 'futuraBold';
  margin: 2px;
  padding-bottom: 25px;
}

.inquiry-form select,
.inquiry-form input,
.inquiry-form textarea,
.sign-form input {
  border: 1px solid black;
  border-radius: 3px;
}

.courier-form input {
  border: 1px solid black;
  border-radius: 3px;
}

.courier-form select {
  border: 1px solid black;
  border-radius: 3px;
}

.account-signin {
  font-family: 'futuraMedium';
  font-size: 1rem;
  color: #ffffff;
}

#deleteBtn {
  background-color: #d2042d;
  margin-top: 0;
}

#deleteBtn:hover {
  background-color: whitesmoke;
  border: 1px solid #d2042d;
  color: #d2042d;
}

.affiliation {
  padding-left: 60px;
  text-align: left;
}

.affiliation .affiliation-label {
  color: #828282;
}

.container-contains-footer{
  padding: 0 0 450px 0 !important;
  margin-bottom: 0px !important;
}

.row-no-right-margin{
  margin-right: 0 !important;
}

.about-fte-text {
  text-decoration-line: underline;
  text-underline-offset: 20px;
  text-decoration-thickness: 4px;
  text-decoration-color: #F5DD4B;
}

.about-us-bottom-margin {
  margin-bottom: 40px;
}

.about-fte-p-text {
  text-align: justify;
}

.h2-no-top-margin {
  margin-top: 0px !important;
}

.mission-vision {
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.mission-vision-box {
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 15px;
  background-color: #1a1a1a;
  opacity: 0.8;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  height: min-content;
  border-radius: 36px;
  max-width: 987px;
}

.mission-vision-h2 {
  color: #ffffff !important; 
  font-size: 38px !important;
}

.mission-vision-p {
  color: #ffffff !important;
  font-style: italic;
  font-size: 22px !important;
}

.ship-step-note {
  width: 450px;
  height: min-content;
  border: 7px solid rgba(248,213,88,255);
  background-color: rgba(247,219,118,255);
  margin: 50px 50px 50px 0px;
  padding: 10px 30px;
  border-radius: 20px;
}

.ship-step-note-text {
  width: 100%;
  margin: 20px 0;
  text-align: justify;
  color: #1a1a1a;
}

/* RESPONSIVE */

@media screen and (min-width: 1140px) {
  .parallax,
  .parallax-prohibited-items,
  #parallax-about-us {
    width: 100%;
  }
}

@media screen and (max-width: 1140px) {
  h1 {
    font-size: 25px;
  }

  p {
    font-size: 20px;
  }

  .contact-us p {
    font-size: 1.5em;
  }

  .container-fluid {
    text-align: center;
  }

  #mainNavbar {
    margin-left: 0;
  }

  #rightNavbar {
    text-align: center;
    padding: 0.28em;
  }

  img#about-us-logo {
    height: 200px;
  }

  img#footer-logo {
    min-height: 4em;
  }

  .parallax,
  .parallax-prohibited-items {
    margin-top: 7em;
    width: 100%;
  }

  #parallax-about-us {
    margin-top: 7em !important;
    width: 100%;
  }

  .btn-track {
    margin-top: 3em;
    width: 200px;
  }

  .btn-upload {
    width: 100%;
  }

  .btn-nav-login,
  .btn-nav-user {
    width: auto;
    padding: 0;
  }

  .tracking-number {
    font-size: 16px;
    margin-right: 1em;
  }

  .about-fte-text {
    font-size: 45px !important;
  }

  .h2-no-top-margin, .mission-vision-h2{
    font-size: 32px !important;
  }

  .about-fte-p-text, .mission-vision-p {
    font-size: 16px !important;
  }

  .row-no-right-margin {
    padding: 0 20px;
  }
  
  #parallax-about-us {
    padding-bottom: 200px !important;
  }

  .navbar-header {
    float: none;
  }

  .navbar-left,.navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none!important;
  }

  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  
  .navbar-nav>li {
    float: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .collapse.in{
    display:block !important;
  }

  img#header-logo {
    margin-left: 1em;
    padding: 0.5em;
    text-align: center;
  }

  #mainNavbar {
    display: none;
  }

  #rightNavbar {
    display: none;
  }
}

@media screen and (max-width: 1075px) {
  .progress-checklist-container {
    padding-left: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #rightNavbarMobile,
  #nav a {
    font-size: 20px;
  }

  #rightNavbarMobile {
    margin-top: 31px;
  }

  #nav li {
    padding: 0.4em;
    margin-top: 1em;
  }

  #loginPanel {
    left: 0;
    width: -webkit-fill-available;
  }

  #userMenu {
    left: 75%;
    top: 100px;
  }

  .col-center {
    padding: 0px;
  }
}

@media screen and (max-width: 990px) {
  .progress-checklist-parent-container {
    display: none;
  }

  .ship-step-parent-container {
    width: 100%;
    padding: 0;
  }

  .ship-step-row-container {
    margin: auto;
    width: 500px;
  }

  .ship-step-container {
    padding-left: 0;
    padding-right: 0;
  }
  
  .ship-step-note{
    margin-top: 50px;
  }
}

@media screen and (max-width: 615px) {
  .shipment-cost-details-button-container {
    width: 100%;
  }
}

@media screen and (max-width: 510px) {
  .shipment-cost-details-button-container,
  .ship-step-row-container,
  .button-primary {
    width: 100%;
  }

  .button-secondary {
    width: 100%;
    margin-bottom: 16px;
  }

  .checkbox-primary {
    width: 100%;
    margin-top: -15px;
    text-align: center;
  }

  .checkbox-primary-text {
    margin-bottom: 26px;
  }

  .ship-step-container {
    padding-top: 20px;
  }

  .ship-step-title-responsive {
    display: none;
  }

  .ship-step-numbering-container {
    display: none;
  }

  .ship-step-container-responsive {
    display: block;
  }

  .ship-step-content {
    padding-left: 18px;
    padding-right: 20px;
    width: 100%;
  }

  .ship-step-content-container,
  .previous-next-button-group {
    max-width: 100%;
  }

  .package-type-icon-container {
    margin-top: 25px !important;
  }

  .package-type-label {
    margin-top: 10px;
  }

  .ship-step-title-cost {
    margin-top: 20px;
  }

  #sub-menu li a {
    padding: 5px 8px;
    margin: 0 10px;
  }

  #sub-menu li a.active {
    padding-bottom: 4px;
    font-size: 20px;
    top: 5px;
  }

  .track-label {
    font-size: 16px !important;
  }

  .detail-two,
  .detail-label-two {
    text-align: left;
  }

  .inquiry-header {
    font-size: 1.75rem;
    margin: 4rem 2rem;
  }

  .inquiry-label {
    font-size: 1.5rem;
  }

  .ship-step-note-text {
    text-align: start;
  }
}

@media screen and (max-width: 414px) {
  img#about-us-logo {
    height: 150px;
  }

  #rightNavbarMobile {
    float: none;
    margin: 0;
    padding: 1em;
  }

  .tablink {
    font-size: 12px;
  }

  #loginPanel,
  #userMenu {
    left: 0;
    width: -webkit-fill-available;
    margin-top: 65px;
  }

  .parallax,
  .parallax-prohibited-items,
  #parallax-about-us {
    block-size: auto;
    padding-bottom: 30vh;
  }
}

@media screen and (min-height: 980px) {
  .parallax {
    height: 1366px !important;
  }
}

@media screen and (max-width: 1450px) {
  #mainNavbar{
    margin-left: 0;
  }
}

@media screen and (min-width: 1140px){
  #mainNavbarMobile {
    display: none !important;
  }

  #rightNavbarMobile {
    display: none !important;
  }
}

.waybill-form-header {
  font-family: futuraBold;
  text-align: center;
  font-size: large;
}

.waybill-detail {
  margin-bottom: 0.8em;
  font-size: 16px;
}

.waybill-dimensions {
  margin: 0 auto;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.disabled {
  cursor: not-allowed;
}

.mode-of-transportation-select {
  display: none;
}

.radio-primary-label{
  font-family: roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.radio-primary{
  input[type="radio"] {
    margin-left: 0;
  }
}

.radio-description{
  padding-left: 26px;
  font-size: 12px;
  font-family: roboto;
  font-style: normal;
  font-weight: 500;
  color: #828282;

  i{
    font-size: 10px;
    color: black;
  }
}

.about-us-container{
  padding-top: 30px;
  padding-left: 150px;
  width: 60%;
  img{
    margin-left: -20px !important; 
  }
}

.about-us-main-container{
  background: #1E1E1E;
  position: relative;
  padding: 0 0 250px 0 !important;
  margin-bottom: 0px !important;
}

.about-us-content{
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
 
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: futuraMedium;
  height: 100%;


  .underlined{
    text-decoration: underline;
    font-weight: 700;
  }
}
.about-us-title{
  font-family: futuraBold;
  color: white;
  font-weight: 700;
}

.about-us-subtitle{
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
}

.btn-about-us{
  border: none;
  width: 240px;
  height: 48px;
  background: #FADC06;
  font-size: 16px;
}

.btn-about-us-container{
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid white;
  margin-bottom: 40px;
}

.about-us-mission-vision{
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .about-us-container{
    padding-left: 50px;
    width: 90%;
  }
}

.rates-container{
  display: flex;
  justify-content: center;
  min-height: 980px !important;
}