.user-container {
  text-align: left;
  margin-bottom: 64px;
}

.users-header {
  display: flex;
  justify-content: space-between;
}

.users-add-btn {
  font-family: futuraMedium;
  font-size: 16px;
  text-align: center;

  color: #f7d700;
  background-color: #000;

  height: 45px;

  padding: 12px;
  margin-top: 18px;

  border-radius: 10px;
  box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.25);
}

.invite-link-btn {
  font-family: futuraMedium;
  font-size: 16px;
  text-align: center;

  color: #f7d700;
  background-color: #000;

  height: 45px;

  padding: 12px;
  margin-top: 18px;
  margin-right: 16px;

  border-radius: 10px;
  box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.25);
}

.users-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-bottom: 24px;
}

.users-pagination {
  margin-top: 24px;
  margin-left: auto;
}

.users-edit-link {
  font-family: futuraMedium;
  font-size: 12px;
  color: #38b0d4;
}
