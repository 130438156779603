.common-table {
  display: block;
  overflow: auto;

  max-height: 800px;
  min-height: 300px;

  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.25);
}

.common-table .headers {
  font-family: futuraMedium;
  color: #000;

  font-weight: 900;
  text-transform: uppercase;
  font-size: 13px;

  border-bottom: 1px solid #d7d7d7;
}

.common-table .row {
  display: flex;
  align-items: center;
  background-color: #fff;

  margin: 5px 0;
}

.common-table-body .row {
  width: min-content;
  padding-top: 7px;
  padding-bottom: 7px;

  color: #000;
  font-size: 12px;
  border-bottom: 1px solid #d7d7d7;
}

.common-table-body .row .cell {
  font-family: futuraMedium;
  font-weight: 100;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  flex-grow: 1;
  padding-left: 20px;
}

.common-table .headers .cell,
.common-table-body .row .cell {
  max-width: 100%;
  background-color: inherit;
  padding: 6px 10px;
}

.common-table .headers .cell {
  display: flex;
  padding: 14px 10px;
}

.sort-icons-container {
  position: relative;
  margin-left: 8px;
  margin-top: -2px;
}
.sort-icon,
.sort-icon-active {
  font-size: 14px;
  color: #d7d7d7;
  position: absolute;
}
.sort-icon-active {
  color: rgb(101, 151, 226);
}

.common-table-no-data {
  width: 100%;
  min-height: 120px;
  padding: 32px;

  font-family: futuraBold;
  font-size: 24px;
}

.common-table .headers .checkbox-column {
  display: block;
  justify-content: center;
  width: inherit;
}

.common-table .common-table-body .checkbox-column {
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.row-selected {
  background-color: rgb(240, 240, 195) !important;
}

.sticky-cell {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: inherit;
}

@media screen and (max-width: 1140px) {
  .common-table .row {
    margin: 0px;
    padding: 0px;
  }
}

.same-day-delivery {
  background-color: #fadc06 !important;
}