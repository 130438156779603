.sales-container {
  text-align: left;
  padding-top: 40px;
}

#sales-filter {
  float: none;
  width: 200px;
  font-size: 12px;
}

.sales-filter-container {
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 24px;
  justify-content: end;
  padding-right: 18px;
}
.sales-filter-search {
  width: 50%;
}
.sales-filter-dropdowns {
  width: 25%;
}

.stats-chart-recent-orders-container {
  /* display: flex;
  justify-content: space-between; */
  margin-top: 30px;
}

.stats-chart-recent-orders-container .wrapper {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.stats-container {

}

.stats-container .wrapper {
  display: flex;
  flex-direction: column;

  /* Auto layout */
  padding: 40px;
  gap: 10px;

  /* White */

  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.stat-label { 
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.stat-header-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.0375em;

  color: #A99939;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.export-btn{
  font-family: futuraMedium;
  font-size: 16px;
  text-align: center;

  color: #f7d700;
  background-color: #000;

  padding: 12px;
  margin-top: 18px;

  border-radius: 10px;
  box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.25);
}

.stat-body-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.0375em;

  /* Value Color */

  color: #0B1354;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.stat-item {
  display: flex;
  justify-content: space-between;
}

.recent-order-item {
  display: flex;
  justify-content: space-between;
}

.chart-container {
  /* display: flex;
  justify-content: center; */
  margin-bottom: 40px;
}

.chart-container .wrapper {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  max-width: 100%;
}

.recent-order-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
}

.updated-time {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(130, 130, 130, 0.4);
}

.recent-order-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(130, 130, 130, 0.4);
}

.recent-order-item-price {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #65AD72;
}

.recent-order-item-location {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #828282;
}

.recent-order-item-date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #828282;
}

.chart-header-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #828282;
  padding-left: 20px;
}

.chart-header-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.0375em;
  color: #0B1354;
  padding-left: 20px;
}