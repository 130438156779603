.box-container {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 20px;
}

.box:not(:last-child) {
  margin-right: 10px;
}

.box {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 125px;
  height: 125px;

  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.box-status {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #5684B9;
  text-align: center;
}

.box-count {
  width: 93px;
  height: 38px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.0375em;
  text-align: center;
  color: #0B1354;
  flex: none;
  order: 0;
  flex-grow: 0;
}