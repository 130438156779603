.home-carousel {
    margin-top: 100px;
  }
  
  .carousel.carousel-slider {
    height: calc(100vh - 300px);
  }
  
  .home-carousel-first-slide {
    background: #fff100;
    height: 100%;
    margin:0;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .home-carousel-first-slide-content{
    width: 80%;
    h1 {
      margin: 0;
      padding-bottom: 10px;
      font-size: 48px;
      line-height: 72px;
    }
  
    p {
      margin: 0;
      font-size: 34px;
      line-height: 51px;
    }
  }
  
  .home-carousel-second-slide {
    background: #1e1e1e;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      object-fit: contain;
      max-width: 70%;
    }
  }
  
  .btn-home-carousel {
    margin-left: 0;
    margin-top: 30px;
    border: 1px solid #fadc06;
    padding: 10px;
    width: 160px;
  }
  
  .carousel .slider.animated {
    height: 100%;
  }
  
  .carousel .slider-wrapper {
    height: 100%;
  }
  
  .carousel .slider{
    height: 100%;
  }

  @media screen and (max-width: 1000px) {
    .carousel.carousel-slider {
      height: calc(100vh - 210px);
    }
  
  
  
    .home-carousel-first-slide {
      padding: 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      h1 {
        margin: 0;
        padding-bottom: 10px;
        font-size: 32px;
        line-height: 44px;
        font-weight: 500 !important;
      }
  
      p {
        margin: 0;
        font-size: 20px;
        line-height: 30px;
      }
    }
  
    .home-carousel-second-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
  }
  
  .arrow-left-carousel {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: white;
    margin-left: 20px;
    border: none;  
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    i{
      font-size: 20px;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 768px) {
    .arrow-left-carousel{
      display: none !important;
    }
    .arrow-right-carousel{
      display: none !important;
    }
    .home-carousel-second-slide {
      img {
        object-fit: contain;
        max-width: 90%;
      }
    }
  }
  .arrow-right-carousel {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: white;
    border: none;  
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px; 
  
    i{
      font-size: 20px;
      text-align: center;
    }
  }
  
  .arrow-right-carousel {
    position: absolute;
    z-index: 2;
    top: calc(50% - 15px);
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: white;
    border: none;  
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px; 
  
    i{
      font-size: 20px;
      text-align: center;
    }
  }
  
  .arrow-right-carousel:disabled{
    background-color: gray;
  }
  
  .arrow-left-carousel:disabled{
    background-color: gray;
  }