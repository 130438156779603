.delete-modal{
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
  	left: 0;
  	right: 0;
  	bottom: 0;
	z-index: 5;
	height: 100vh;
  	width: 100vw;
}

.delete-modal .delete-modal-overlay{
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
	background: #888;
	height: 100%;
    width: 100%;
	opacity: 0.5;
}

.delete-modal .delete-modal-component{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 24px;
	background: #FFFFFF;
	height: 300px;
	width: 450px;
	z-index: 15
}

.delete-modal-component .delete-modal-message{
	padding: 20px 20px 0;
	width: 100%;
	flex: 8;
}

.delete-modal-component .delete-modal-message h3{
	color: #383838
}

.delete-modal-component .delete-modal-message p{
	color: #383838
}

.delete-modal-message p{
	font-size: 14px;
	font-weight: normal;
}

.delete-modal-component .delete-modal-buttons{
	padding: 0 80px 20px;
	width: 100%;
	flex: 2;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.delete-modal-buttons .delete-modal-buttons-cancelBtn{
	border-radius: 10px;
	height: 40px;
	width: 100px;
	border: 0;
	background: #C1C1C1;
	color: #FFFFFF;
	font-weight: normal;
}

.delete-modal-buttons .delete-modal-buttons-deleteBtn{
	border-radius: 10px;
	height: 40px;
	width: 100px;
	border: 0;
	background: #F15E5E;
	color: #FFFFFF;
	font-weight: normal;
}

.delete-modal-buttons button:hover {
	transition: transform 0.3s ease-out;
	transform: scale(1.05);
}