.waybill-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 40px 0;
}
.waybill-papers {
  background-color: white;
}
.printable-document td {
  border: 1px solid black;
  vertical-align: text-top;
}
.air-waybill {
  padding: 20px;
}
.air-waybill-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media print {
  .no-print {
    display: none !important;
  }
}