.common-tab-wrapper {
  background: #fff;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.25);
}

.common-tabs-container {
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #d7d7d7;
}

.common-tab,
.common-tab-active {
  cursor: pointer;
  font-family: futuraMedium;
  font-weight: 900;
  font-size: 14px;
  color: #000;
  padding: 10px;
  border-radius: 10px;
}

.common-tab-active {
  background-color: #f5dd4b;
}

.common-tab-content {
  overflow-y: auto;
  max-height: 500px;
}

@media screen and (max-width: 760px) {
  .common-tab,
  .common-tab-active {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .common-tabs-container {
    justify-content: space-evenly;
  }
}